/* eslint-disable max-lines */
import React, {Component} from 'react';

import {autobind} from 'core-decorators';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import Destination from '../form/Destination';
import Select from '../form/Select';

import Button from 'Frontend/components/Button';

import {Info, LocationSearch} from 'Frontend/assets/images/icons';
import {FONTS} from 'Frontend/utils/fonts';
import {clampCss, iconAsBackground, pxToVw} from 'Frontend/utils/helpers';
import {MEDIA} from 'Frontend/utils/mediaQueries';

/**
 * QuickLead
 *
 * @property {*} prop Prop comment.
 *
 * @class QuickLead
 * @extends {Component}
 */
class QuickLead extends Component {
    static propTypes = {
        className: PropTypes.string,
        productPlaceholder: PropTypes.string,
        searchInfo: PropTypes.string,
        searchPlaceholder: PropTypes.string,
        searchTitle: PropTypes.string,
        // eslint-disable-next-line react/boolean-prop-naming
        showSearchIcon: PropTypes.bool
    }

    static defaultProps = {
        className: '',
        productPlaceholder: 'Haarteile, Perücken, …',
        searchInfo: 'Wir bieten unseren Service aktuell nur in und um München an.',
        searchPlaceholder: 'Stadt, Straße, PLZ, Standort',
        searchTitle: 'Ich suche:',
        showSearchIcon: true
    }

    /**
     * Creates an instance of QuickLead.
     *
     * @param {Object} props Component props.
     * @memberof QuickLead
     */
    constructor(props) {
        super(props);

        this.state = {
            hasStoresInRange: 'unknown',
            place: null,
            product: null,
            searchRangeKm: 20
        };
    }

    /**
     * Handle the survey tracking.
     * @param {Event} e click event
     * @memberof QuickLead
     */
    @autobind
    handleTrack(e) {
        window.dataLayer.push({
            event: 'surveyShortInit',
            PageTitle: document.title.innerText,
            URL: window.location.href
        });
    }

    /**
     * Handle destination km change.
     * @param {Number} searchRangeKm Distance to the center in km
     * @memberof QuickLead
     */
    @autobind
    handleKmChange(searchRangeKm) {
        this.setState({searchRangeKm});
    }

    /**
     * Handle product change.
     * @param {String} product Selected product
     * @memberof QuickLead
     */
    @autobind
    handleProductChange(product) {
        this.setState({product});
    }

    /**
     * Handle destination place change.
     * @param {Object} place Selected place
     * @memberof QuickLead
     */
    @autobind
    async handlePlaceChange(place) {
        this.setState({
            hasStoresInRange: 'unknown',
            place
        });

        if (place) {
            // Check if there are stores in range
            let hasStoresInRange = 'unknown';

            const {geo} = place;
            const [lon, lat] = geo.split(',');

            const body = JSON.stringify({
                lat,
                lon
            });

            try {
                const response = await fetch('/api/validate-place', {
                    body,
                    method: 'POST'
                });

                ({hasStoresInRange} = await response.json());

                this.setState({hasStoresInRange});
            } catch (error) {
                // can not update store info
            }
        }
    }

    /**
     * Renders the Component.
     *
     * @returns {JSX} Component.
     * @memberof QuickLead
     */
    render() {
        const {
            className, productPlaceholder, searchInfo,
            searchPlaceholder, searchTitle, showSearchIcon
        } = this.props;
        const {hasStoresInRange, place, product, searchRangeKm} = this.state;
        const minRange = 1;
        const maxRange = 100;
        const hasInvalidData = (
            product === null
            || place === null
            || searchRangeKm < minRange
            || searchRangeKm > maxRange
            || hasStoresInRange !== true
        );

        let link = '/interview-short';

        if (!hasInvalidData) {
            link += `?place=${encodeURIComponent(place.geo)}`;
            link += `&range=${encodeURIComponent(searchRangeKm)}`;
            link += `&product=${encodeURIComponent(product)}`;
        }

        const options = [
            {
                id: 'repair',
                name: 'Reparatur'
            },
            {
                id: 'hairpiece',
                name: 'Haarteil'
            },
            {
                id: 'toupee',
                name: 'Haarersatz für Männer (Toupet)'
            },
            {
                id: 'wig',
                name: 'Perücke'
            },
            {
                id: 'other',
                name: 'Weiß nicht'
            }
        ];

        return (
            <QuickLeadElement className={className}>
                <CenterRow>
                    {showSearchIcon && (
                        <>
                            <LocationIcon />
                            <IconSpacer />
                        </>
                    )}
                    <Text>{searchTitle}</Text>
                </CenterRow>
                <Spacer />
                <WrapRow>
                    <StyledSelect
                        options={options}
                        placeholder={productPlaceholder}
                        value={product || ''}
                        onChange={this.handleProductChange}
                    />
                    <Spacer />
                    <StyledDestination
                        placeholder={searchPlaceholder}
                        searchRangeKm={searchRangeKm}
                        onKmChange={this.handleKmChange}
                        onPlaceChange={this.handlePlaceChange}
                    />
                </WrapRow>
                <InfoRow>
                    {hasStoresInRange !== false && (
                        <>
                            <InfoIcon />
                            <IconSpacer />
                            <TextSmall>{searchInfo}</TextSmall>
                        </>
                    )}
                    {hasStoresInRange === false && (<StoreInfo>Kein Zweithaarstudio in der Nähe</StoreInfo>)}
                </InfoRow>
                <BottomSpacer />
                <Row>
                    <StyledButton
                        isDisabled={hasInvalidData}
                        link={link}
                        text="Zweithaarsalon finden"
                        onClick={this.handleTrack}
                    />
                </Row>
            </QuickLeadElement>
        );
    }
}

export default QuickLead;

const LocationIcon = styled.div`
    background-image: url(${iconAsBackground(LocationSearch)});
    background-position: center;
    background-repeat: no-repeat;
    background-size: contain;
    flex-shrink: 0;
    height: 46px;
    width: 46px;
`;

const InfoIcon = styled.div`
    background-image: url(${iconAsBackground(Info)});
    background-position: center;
    background-repeat: no-repeat;
    background-size: contain;
    flex-shrink: 0;
    height: 16px;
    width: 16px;
`;

const StyledButton = styled(Button)`
    width: 100%;

    ${MEDIA.TABLET.UP} {
        margin-left: auto;
        width: auto;
    }

    ${MEDIA.DESKTOP.UP} {
        margin-left: auto;
        width: auto;
    }
`;

const Row = styled.div`
    display: flex;
    flex-direction: row;
    width: 100%;
`;

const InfoRow = styled(Row)`
    margin-top: 16px;
`;

const CenterRow = styled(Row)`
    align-items: center;
    margin-bottom: 16px;
`;

const WrapRow = styled.div`
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    width: 100%;
`;

const StyledSelect = styled(Select)`
    flex: 1;
`;

const StyledDestination = styled(Destination)`
    width: 100% !important;

    ${MEDIA.DESKTOP.UP} {
        flex: 1;
    }
`;

const QuickLeadElement = styled.div`
    align-items: flex-start;
    background: white;
    border-radius: 8px;
    display: flex;
    flex-direction: column;
    margin-left: auto;
    margin-right: auto;
    padding-bottom: ${clampCss(40, 'm')};
    padding-left: ${clampCss(32, 'm')};
    padding-right: ${clampCss(32, 'm')};
    padding-top: ${clampCss(32, 'm')};
    position: relative;
    width: 100%;

    ${MEDIA.TABLET.UP} {
        padding-bottom: ${clampCss(48, 't')};
        padding-left: ${clampCss(40, 't')};
        padding-right: ${clampCss(40, 't')};
        padding-top: ${clampCss(32, 't')};
    }

    ${MEDIA.DESKTOP.UP} {
        padding-bottom: max(48px, ${pxToVw(48, 'd')});
        padding-left: max(40px, ${pxToVw(40, 'd')});
        padding-right: max(40px, ${pxToVw(40, 'd')});
        padding-top: max(32px, ${pxToVw(32, 'd')});
    }
`;

const IconSpacer = styled.div`
    flex-shrink: 0;
    width: 8px;
`;

const Spacer = styled.div`
    flex-shrink: 0;
    height: 16px;
    width: 0px;

    ${MEDIA.TABLET.UP} {
        width: 24px;
    }
`;

const BottomSpacer = styled.div`
    flex-shrink: 0;
    height: 40px;
    width: 24px;
`;

const Text = styled.div`
    ${FONTS.SEMIBOLD}
    color: ${({theme}) => theme.colors.textColor};
    font-size: ${clampCss(20, 'm')};
    letter-spacing: ${clampCss(1.2, 'm')};
    line-height: ${clampCss(24, 'm')};

    ${MEDIA.TABLET.UP} {
        font-size: ${clampCss(20, 't')};
        letter-spacing: ${clampCss(1.2, 't')};
        line-height: ${clampCss(24, 't')};
    }

    ${MEDIA.DESKTOP.UP} {
        font-size: max(20px, ${pxToVw(20, 'd')});
        letter-spacing: max(1.2px, ${pxToVw(1.2, 'd')});
        line-height: max(24px, ${pxToVw(24, 'd')});
    }
`;

const TextSmall = styled.div`
    color: ${({theme}) => theme.colors.textColor};
    ${FONTS.REGULAR}
    font-size: ${clampCss(12, 'm')};
    letter-spacing: ${clampCss(0.5, 'm')};
    line-height: ${clampCss(16.8, 'm')};

    ${MEDIA.TABLET.UP} {
        font-size: ${clampCss(12, 't')};
        letter-spacing: ${clampCss(0.5, 't')};
        line-height: ${clampCss(16.8, 't')};
    }

    ${MEDIA.DESKTOP.UP} {
        font-size: max(12px, ${pxToVw(12, 'd')});
        letter-spacing: max(0.5px, ${pxToVw(0.5, 'd')});
        line-height: max(16.8px, ${pxToVw(16.8, 'd')});
    }
`;

const StoreInfo = styled.p`
    ${FONTS.REGULAR}
    color: #D56B50;
    font-size: ${clampCss(12, 'm')};
    letter-spacing: ${clampCss(0.5, 'm')};
    line-height: ${clampCss(16.8, 'm')};
    margin: 0;
    text-align: center;
    width: 100%;

    ${MEDIA.TABLET.UP} {
        font-size: ${clampCss(12, 't')};
        letter-spacing: ${clampCss(0.5, 't')};
        line-height: ${clampCss(16.8, 't')};
    }

    ${MEDIA.DESKTOP.UP} {
        font-size: max(12px, ${pxToVw(12, 'd')});
        letter-spacing: max(0.5px, ${pxToVw(0.5, 'd')});
        line-height: max(16.8px, ${pxToVw(16.8, 'd')});
    }
`;
import React, {Component} from 'react';

import {autobind} from 'core-decorators';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import {FONTS} from 'Frontend/utils/fonts';
import {clampCss, pxToVw} from 'Frontend/utils/helpers';
import {MEDIA} from 'Frontend/utils/mediaQueries';

/**
 * Select
 *
 * @property {*} prop Prop comment.
 *
 * @class Select
 * @extends {Component}
 */
class Select extends Component {
    static propTypes = {
        options: PropTypes.array.isRequired,
        className: PropTypes.string,
        placeholder: PropTypes.string,
        type: PropTypes.oneOf(['text', 'date', 'email', 'tel']),
        validation: PropTypes.string,
        value: PropTypes.string,
        onChange: PropTypes.func
    }

    static defaultProps = {
        className: '',
        onChange() {},
        placeholder: null,
        type: 'text',
        validation: null,
        value: ''
    }

    /**
     * Handles the field content change in the Select.
     *
     * @param {Event} e The onChange event.
     *
     * @memberof Select
     */
    @autobind
    handleChange(e) {
        const {onChange} = this.props;

        onChange(e.target.value);
    }

    /**
     * Renders the Component.
     *
     * @returns {JSX} Component.
     * @memberof Select
     */
    render() {
        const {className, options, placeholder, type, validation, value} = this.props;

        return (
            <SelectElement className={className}>
                <SelectWrapper>
                    <SelectField
                        pattern={validation}
                        type={type}
                        value={value}
                        onChange={this.handleChange}
                    >
                        <option value="" disabled>{placeholder}</option>
                        {options.map(({id, name}) => <option key={id} value={id}>{name}</option>)}
                    </SelectField>
                </SelectWrapper>
            </SelectElement>
        );
    }
}

export default Select;

const SelectElement = styled.div`
    display: flex;
    position: relative;
`;

const SelectWrapper = styled.div`
    border-color: #C8C8D0;
    border-style: solid;
    border-width: 1px;
    height: ${clampCss(47, 'm')};
    position: relative;
    width: 100%;

    ${MEDIA.TABLET.UP} {
        height: ${clampCss(47, 't')};
    }

    ${MEDIA.DESKTOP.UP} {
        height: max(47px, ${pxToVw(47, 'd')});
    }
`;

const SelectField = styled.select`
    ${FONTS.SEMIBOLD}
    -moz-appearance: none;
    -webkit-appearance: none;
    appearance: none;
    background: transparent;
    border: none;
    color: #8E8E99;
    font-size: 16px;
    height: 100%;
    letter-spacing: ${clampCss(0.5, 'm')};
    line-height: ${clampCss(16.8, 'm')};
    outline: none;
    padding: 0 ${clampCss(12.5, 'm')}; 
    width: 100%;

    &[type="date"] {
        text-transform: uppercase;
    }
    &:focus, :active {
        outline: none;
    }

    &::placeholder, ::-ms-input-placeholder {
        color: #8E8E99;
    }

    &::-webkit-inner-spin-button {
        display: none;
    }

    &::-webkit-calendar-picker-indicator {
        display: none;
    }

    ${MEDIA.TABLET.UP} {
        font-size: ${clampCss(12, 't')};
        letter-spacing: ${clampCss(0.5, 't')};
        line-height: ${clampCss(16.8, 't')};
        padding: 0 ${clampCss(12.5, 't')};
    }

    ${MEDIA.DESKTOP.UP} {
        font-size: max(12px, ${pxToVw(12, 'd')});
        letter-spacing: max(0.5px, ${pxToVw(0.5, 'd')});
        line-height: max(16.8px, ${pxToVw(16.8, 'd')});
        padding: 0 max(12.5px, ${pxToVw(12.5, 'd')});
    }
`;
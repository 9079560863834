import React, {Component} from 'react';

import {autobind} from 'core-decorators';
import PropTypes from 'prop-types';
import Autocomplete from 'react-google-autocomplete';
import styled from 'styled-components';

import {Search} from 'Frontend/assets/images/icons';
import {FONTS} from 'Frontend/utils/fonts';
import {clampCss, pxToVw} from 'Frontend/utils/helpers';
import {MEDIA} from 'Frontend/utils/mediaQueries';

/**
 * Destination
 *
 * @property {*} prop Prop comment.
 *
 * @class Destination
 * @extends {Component}
 */
class Destination extends Component {
    static propTypes = {
        className: PropTypes.string,
        placeholder: PropTypes.string,
        searchRangeKm: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
        onKmChange: PropTypes.func,
        onPlaceChange: PropTypes.func
    }

    static defaultProps = {
        className: '',
        onKmChange() {},
        onPlaceChange() {},
        placeholder: null,
        searchRangeKm: 20
    }

    /**
     * Creates an instance of Destination.
     *
     * @param {Object} props Component props.
     * @memberof Destination
     */
    constructor(props) {
        super(props);

        this.state = {open: false};
    }

    /**
     * Handles the place select.
     *
     * @param {Event} e The slider change event.
     * @memberof Destination
     */
    @autobind
    handleDistance(e) {
        const {onKmChange} = this.props;

        onKmChange(e.target.value);
    }

    /**
     * Handles the place select.
     *
     * @param {Object} place The google place object.
     * @memberof Destination
     */
    @autobind
    handlePlace(place) {
        const {onPlaceChange} = this.props;
        const lat = place?.geometry?.location?.lat;
        const lng = place?.geometry?.location?.lng;

        if (lat && lng) {
            onPlaceChange({
                address: place.formatted_address,
                geo: `${lng()},${lat()}`
            });

            this.setState({open: false});
        }
    }

    /**
     * Handles the slider opening state.
     *
     * @memberof Destination
     */
    @autobind
    handleOpenSlider() {
        this.setState(old => ({open: !old.open}));
    }

    /**
     * Renders the Component.
     *
     * @returns {JSX} Component.
     * @memberof Destination
     */
    render() {
        const {open} = this.state;
        const {className, placeholder, searchRangeKm} = this.props;
        const min = 1;
        const max = 100;
        // eslint-disable-next-line @nfq/no-magic-numbers
        const perc = ((searchRangeKm - min) * 100) / (max - min);

        return (
            <DestinationElement className={className}>
                <AutoCompleteWrapper>
                    <StyledSearch color="#8E8E99" />
                    <StyledAutocomplete
                        componentRestrictions={{country: 'de'}}
                        placeholder={placeholder}
                        types={['geocode']}
                        onPlaceSelected={this.handlePlace}
                    />
                </AutoCompleteWrapper>
                <KmWrapper onClick={this.handleOpenSlider}>
                    {searchRangeKm} Km <span>+</span>
                </KmWrapper>
                {open && (
                    <RangeWrapper>
                        <RangeSlider
                            max={max}
                            min={min}
                            perc={perc}
                            type="range"
                            value={searchRangeKm}
                            onChange={this.handleDistance}
                        />
                        <LabelWrapper>
                            <RangeSliderLabel left={perc}>{searchRangeKm} Km</RangeSliderLabel>
                        </LabelWrapper>
                    </RangeWrapper>
                )}
            </DestinationElement>
        );
    }
}

export default Destination;

const DestinationElement = styled.div`
    display: flex;
    position: relative;
    width: 100%;

    ${MEDIA.TABLET.UP} {
        width: 75%;
    }

    ${MEDIA.DESKTOP.UP} {
        width: 50%;
    }
`;

const AutoCompleteWrapper = styled.div`
    border: 1px solid #C8C8D0;
    border-right: none;
    flex: 1 1 auto;
    height: ${clampCss(47, 'm')};
    padding: 0 0 0 ${clampCss(37, 'm')};
    position: relative;

    ${MEDIA.TABLET.UP} {
        height: ${clampCss(47, 't')};
        padding: 0 0 0 ${clampCss(37, 't')};
    }

    ${MEDIA.DESKTOP.UP} {
        height: max(47px, ${pxToVw(47, 'd')});
        padding: 0 0 0 max(37px, ${pxToVw(37, 'd')});
    }
`;

const StyledSearch = styled(Search)`
    height: ${clampCss(16, 'm')};
    left: ${clampCss(12.5, 'm')};
    position: absolute;
    top: 50%;
    transform: translate(0, -50%);
    width: ${clampCss(16, 'm')};

    ${MEDIA.TABLET.UP} {
        height: ${clampCss(16, 't')};
        left: ${clampCss(12.5, 't')};
        width: ${clampCss(16, 't')};
    }

    ${MEDIA.DESKTOP.UP} {
        height: max(16px, ${pxToVw(16, 'd')});
        left: max(12.5px, ${pxToVw(12.5, 'd')});
        width: max(16px, ${pxToVw(16, 'd')});
    }
`;

const StyledAutocomplete = styled(Autocomplete)`
    ${FONTS.SEMIBOLD}
    border: none;
    color: #8E8E99;
    font-size: max(16px, ${clampCss(12, 'm')});
    height: 100%;
    letter-spacing: max(0.7px, ${clampCss(0.5, 'm')});
    line-height: max(22.4px, ${clampCss(16.8, 'm')});
    outline: none;
    width: 100%;

    &:active, :focus {
        outline: none;
    }

    &::placeholder, ::-ms-input-placeholder {
        color: #8E8E99;
    }

    ${MEDIA.TABLET.UP} {
        font-size: ${clampCss(12, 't')};
        letter-spacing: ${clampCss(0.5, 't')};
        line-height: ${clampCss(16.8, 't')};
    }

    ${MEDIA.DESKTOP.UP} {
        font-size: max(12px, ${pxToVw(12, 'd')});
        letter-spacing: max(0.5px, ${pxToVw(0.5, 'd')});
        line-height: max(16.8px, ${pxToVw(16.8, 'd')});
    }
`;

const KmWrapper = styled.div`
    --gap: ${clampCss(6.5, 'm')};
    ${FONTS.SEMIBOLD}
    align-items: center;
    border: 1px solid #C8C8D0;
    color: ${({theme}) => theme.colors.textColor};
    cursor: pointer;
    display: inline-flex;
    flex: 0 0 ${clampCss(85, 'm')};
    font-size: ${clampCss(12, 'm')};
    height: ${clampCss(47, 'm')};
    justify-content: center;
    letter-spacing: ${clampCss(0.5, 'm')};
    line-height: ${clampCss(16.8, 'm')};

    & > * {
        margin-left: var(--gap);
    }

    & span {
        ${FONTS.REGULAR}
    }

    ${MEDIA.TABLET.UP} {
        --gap: ${clampCss(6.5, 't')};
        flex: 0 0 ${clampCss(85, 't')};
        font-size: ${clampCss(12, 't')};
        height: ${clampCss(47, 't')};
        letter-spacing: ${clampCss(0.5, 't')};
        line-height: ${clampCss(16.8, 't')};

        & span {
            font-size: ${clampCss(16, 't')};
            line-height: ${clampCss(16, 't')};
        }
    }

    ${MEDIA.DESKTOP.UP} {
        --gap: max(6.5px, ${pxToVw(6.5, 'd')});
        flex: 0 0 max(85px, ${pxToVw(85, 'd')});
        font-size: max(12px, ${pxToVw(12, 'd')});
        height: max(47px, ${pxToVw(47, 'd')});
        letter-spacing: max(0.5px, ${pxToVw(0.5, 'd')});
        line-height: max(16.8px, ${pxToVw(16.8, 'd')});

        & span {
            font-size: max(16px, ${pxToVw(16, 'd')});
            line-height: max(16px, ${pxToVw(16, 'd')});
        }
    }
`;

const RangeWrapper = styled.div`
    align-items: center;
    background: #fff;
    border: 1px solid #C8C8D0;
    bottom: ${clampCss(-47, 'm', true)};
    display: flex;
    flex-direction: column;
    height: ${clampCss(47, 'm')};
    justify-content: center;
    padding: 0 ${clampCss(16.5, 'm')} 0 ${clampCss(16.5, 'm')};
    position: absolute;
    width: 100%;
    z-index: 1;

    ${MEDIA.TABLET.UP} {
        bottom: ${clampCss(-47, 't', true)};
        height: ${clampCss(47, 't')};
        padding: 0 ${clampCss(16.5, 't')} 0 ${clampCss(16.5, 't')};
    }

    ${MEDIA.DESKTOP.UP} {
        bottom: min(-47px, ${pxToVw(-47, 'd')});
        height: max(47px, ${pxToVw(47, 'd')});
        padding: 0 max(16.5px, ${pxToVw(16.5, 'd')}) 0 max(16.5px, ${pxToVw(16.5, 'd')});
    }
`;

const RangeSlider = styled.input.attrs(({perc}) => ({style: {background: `linear-gradient(to right, #D56B50 0%, #D56B50 ${perc}%, #C8C8D0 ${perc}%, #C8C8D0 100%)`}}))`
    -webkit-appearance: none;
    height: 1px;
    outline: none;
    width: 100%;

    &:focus, :active {
        outline: none;
    }

    &::-webkit-slider-thumb {
        -webkit-appearance: none;
        background: #D56B50;
        border-radius: 50%;
        cursor: ew-resize;
        height: ${clampCss(16, 'm')};
        width: ${clampCss(16, 'm')};
    }

    ${MEDIA.TABLET.UP} {
        &::-webkit-slider-thumb {
            height: ${clampCss(16, 't')};
            width: ${clampCss(16, 't')};
        }
    }

    ${MEDIA.DESKTOP.UP} {
        &::-webkit-slider-thumb {
            height: max(16px, ${pxToVw(16, 'd')});
            width: max(16px, ${pxToVw(16, 'd')});
        }
    }
`;

const LabelWrapper = styled.div`
    height: 0px;
    position: relative;
    width: 100%;
`;

// eslint-disable-next-line @nfq/no-magic-numbers
const RangeSliderLabel = styled.div.attrs(({left}) => ({style: {left: `calc(${left}% + (${8 - left * 0.15}px))`}}))`
    color: #D56B50;
    font-size: ${clampCss(8, 'm')};
    letter-spacing: ${clampCss(0.4, 'm')};
    line-height: ${clampCss(11.2, 'm')};
    position: absolute;
    text-align: center;
    transform: translate(-50%, ${clampCss(6, 'm')});
    width: ${clampCss(40, 'm')};

    ${MEDIA.TABLET.UP} {
        font-size: ${clampCss(8, 't')};
        letter-spacing: ${clampCss(0.4, 't')};
        line-height: ${clampCss(11.2, 't')};
        transform: translate(-50%, ${clampCss(6, 't')});
        width: ${clampCss(40, 't')};
    }

    ${MEDIA.DESKTOP.UP} {
        font-size: max(8px, ${pxToVw(8, 'd')});
        letter-spacing: max(0.4px, ${pxToVw(0.4, 'd')});
        line-height: max(11.2px, ${pxToVw(11.2, 'd')});
        transform: translate(-50%, max(5px, ${pxToVw(5, 'd')}));
        width: max(40px, ${pxToVw(40, 'd')});
    }
`;
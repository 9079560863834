import styled from 'styled-components';

import {Arrow} from 'Frontend/assets/images/icons';
import {FONTS} from 'Frontend/utils/fonts';
import {clampCss, iconAsBackground, pxToVw} from 'Frontend/utils/helpers';
import {MEDIA} from 'Frontend/utils/mediaQueries';

const ButtonElement = styled.a`
    ${FONTS.SEMIBOLD}
    align-items: center;
    background-color: ${({disabled}) => (disabled ? '#C8C8D0' : '#D56B50')};
    color: ${({theme}) => theme.colors.light100};
    cursor: ${({disabled}) => (disabled ? 'not-allowed' : 'pointer')};
    display: flex;
    font-size: ${clampCss(12, 'm')};
    height: ${clampCss(46, 'm')};
    justify-content: center;
    letter-spacing: ${clampCss(0.5, 'm')};
    line-height: ${clampCss(16.8, 'm')};
    padding: 0 ${clampCss(27, 'm')} 0 ${clampCss(27, 'm')};
    pointer-events: ${({disabled}) => (disabled ? 'none' : 'default')};;
    position: relative;
    text-decoration: none;
    text-transform: uppercase;
    transition: background-color 0.2s 0s ease-in-out;
    width: max-content;

    &::after {
        background-image: url(${iconAsBackground(Arrow, {color: '#ffffff'})});
        background-position: right;
        background-size: ${clampCss(32, 'm')} 100%;
        content: '';
        height: ${clampCss(16, 'm')};
        margin-left: 9px;
        position: relative;
        transition: width 0.2s 0s ease-in-out;
        width: ${clampCss(16, 'm')};
        will-change: width;
    }

    &:hover {
        background-color: ${({disabled}) => (disabled ? '#C8C8D0' : '#D56B50')};
        transition: background-color 0.2s 0s ease-in-out;

        &::after {
            width: ${clampCss(32, 'm')};
            transition: width 0.2s 0s ease-in-out;
        }
    }

    ${MEDIA.TABLET.UP} {
        font-size: ${clampCss(12, 't')};
        height: ${clampCss(46, 't')};
        letter-spacing: ${clampCss(0.5, 't')};
        line-height: ${clampCss(16.8, 't')};
        padding: 0 ${clampCss(27, 't')} 0 ${clampCss(27, 't')};

        &::after {
            background-size: ${clampCss(32, 't')} 100%;
            height: ${clampCss(16, 't')};
            width: ${clampCss(16, 't')};
        }

        &:hover ::after {
            width: ${clampCss(32, 't')};
        }
    }

    ${MEDIA.DESKTOP.UP} {
        font-size: max(12px, ${pxToVw(12, 'd')});
        height: max(46px, ${pxToVw(46, 'd')});
        letter-spacing: max(0.5px, ${pxToVw(0.5, 'd')});
        line-height: max(16.8px, ${pxToVw(16.8, 'd')});
        padding: 0 max(27px, ${pxToVw(27, 'd')}) 0 max(27px, ${pxToVw(27, 'd')});

        &::after {
            background-size: max(32px, ${pxToVw(32, 'd')}) 100%;
            height: max(16px, ${pxToVw(16, 'd')});
            width: max(16px, ${pxToVw(16, 'd')});
        }

        &:hover ::after {
            width: max(32px, ${pxToVw(32, 'd')});
        }
    }
`;

export default ButtonElement;
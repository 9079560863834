import React, {Component} from 'react';

import Link from 'next/link';
import PropTypes from 'prop-types';

import ButtonElement from './ButtonElement';

/**
 * Button
 *
 * @property {*} prop Prop comment.
 *
 * @class Button
 * @extends {Component}
 */
class Button extends Component {
    static propTypes = {
        link: PropTypes.string.isRequired,
        text: PropTypes.string.isRequired,
        className: PropTypes.string,
        isDisabled: PropTypes.bool,
        onClick: PropTypes.func
    }

    static defaultProps = {
        className: '',
        isDisabled: false,
        onClick() {}
    }

    /**
     * Renders the Component.
     *
     * @returns {JSX} Component.
     * @memberof Button
     */
    render() {
        const {className, isDisabled, link, onClick: handleClick, text} = this.props;

        return (
            <Link href={link} passHref>
                <ButtonElement className={className} disabled={isDisabled} href={link} onClick={handleClick}>
                    {text}
                </ButtonElement>
            </Link>
        );
    }
}

export default Button;